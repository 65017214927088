<template>
  <div :class="'col-md-' + colSize">
    <div
      class="card text-white card-has-bg click-col"
      :style="{ backgroundImage: 'url(' + 'img/' + imageName + ')' }"
    >
      <img class="card-img d-none" />
      <div class="card-img-overlay d-flex flex-column">
        <div class="card-body" @click="routeRedirect()">
          <small class="card-meta mb-2">{{ title }}</small>
          <h4 class="card-title mt-0">
            <a class="text-white">
              {{ cardBody }}
            </a>
          </h4>
          <small><i class="fa fa-info mr-1"></i> {{ description }}</small>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "router-card",
  props: {
    colSize: {
      type: String,
      default: "4",
    },
    title: String,
    cardBody: String,
    description: String,
    imageName: {
      type: String,
      default: "collab-thumb1.jpg",
    },
    goToRoute: {
      type: String,
      default: "",
    },
  },
  data() {
    return {};
  },
  created() {},
  methods: {
    routeRedirect: function () {
      this.$router.push("/" + this.goToRoute);
    },
  },
};
</script>

<style lang="scss" scoped>
//post card styles
.card {
  border: none;
  transition: all 500ms cubic-bezier(0.19, 1, 0.22, 1);
  overflow: hidden;
  border-radius: 10px;
  min-height: 150px;
  box-shadow: 0 0 12px 0 rgba(0, 0, 0, 0.2);
  animation: fadein 2s;

  @media (max-width: 768px) {
    min-height: 150px;
  }

  @media (max-width: 420px) {
    min-height: 150px;
  }

  &.card-has-bg {
    transition: all 500ms cubic-bezier(0.19, 1, 0.22, 1);
    background-size: 120%;
    background-repeat: no-repeat;
    background-position: center center;
    &:before {
      content: "";
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background: inherit;
      // -webkit-filter: grayscale(0.2);
      // -moz-filter: grayscale(20%);
      // -ms-filter: grayscale(20%);
      // -o-filter: grayscale(20%);
      // filter: grayscale(20%);
    }

    &:hover {
      transform: scale(0.98);
      box-shadow: 0 0 5px -2px rgba(0, 0, 0, 0.3);
      background-size: 130%;
      transition: all 500ms cubic-bezier(0.19, 1, 0.22, 1);

      .card-img-overlay {
        transition: all 800ms cubic-bezier(0.19, 1, 0.22, 1);
        background: rgb(35, 79, 109);
        background: linear-gradient(
          0deg,
          rgba(4, 69, 114, 0.5) 0%,
          rgba(4, 69, 114, 1) 100%
        );
      }
    }
  }
  .card-footer {
    background: none;
    border-top: none;
    .media {
      img {
        border: solid 3px rgba(234, 95, 0, 0.3);
      }
    }
  }
  .card-meta {
    color: orange;
  }
  .card-body {
    transition: all 500ms cubic-bezier(0.19, 1, 0.22, 1);
  }
  &:hover {
    .card-body {
      // margin-top: 30px;
      transition: all 800ms cubic-bezier(0.19, 1, 0.22, 1);
    }
    cursor: pointer;
    transition: all 800ms cubic-bezier(0.19, 1, 0.22, 1);
  }
  .card-img-overlay {
    transition: all 800ms cubic-bezier(0.19, 1, 0.22, 1);
    // background: rgb(35, 79, 109);
    background: linear-gradient(
      0deg,
      rgba(54, 109, 146, 0.3) 0%,
      rgba(73, 69, 125, 0.85) 100%
    );
  }
}

@keyframes fadein {
  from { opacity: 0}
  to   { opacity: 1}
}
</style>
<template>
  <div class="row">
    <div class="col-md-12">
      <md-table
        v-model="searched"
        :md-sort.sync="currentSort"
        :md-sort-order.sync="currentSortOrder"
        :md-sort-fn="customSort"
        md-card
      >
        <md-table-toolbar>
          <h3 class="md-title">Active User Exam List</h3>
          <div class="col-md-4">
            <md-field md-clearable class="md-toolbar-section-end">
              <i
                class="tim-icons icon-zoom-split"
                style="margin-right: 10px"
              ></i>
              <md-input
                placeholder="Search by user's name or email..."
                v-model="search"
                @input="searchOnTable"
              />
            </md-field>
          </div>
        </md-table-toolbar>

        <md-table-empty-state
          md-label="No Active User Exam Available"
          :md-description="`Apparently none of any user has taken any exam right now.`"
        ></md-table-empty-state>

        <md-table-row slot="md-table-row" slot-scope="{ item, index }">
          <md-table-cell md-label="No." style="width: 5%">
            {{ index + 1 }}.
          </md-table-cell>

          <md-table-cell
            md-label="User's Name"
            md-sort-by="user_name"
            style="width: 35%"
          >
            {{ item.user_name }}
          </md-table-cell>

          <md-table-cell md-label="Free Exam" style="width: 5%">
            {{ item.exam_free_flag ? "YES" : "NO" }}
          </md-table-cell>

          <md-table-cell
            md-label="Exam Start Date"
            md-sort-by="exam_start_date"
            style="width: 10%"
          >
            {{ item.exam_start_date }}
          </md-table-cell>

          <md-table-cell
            md-label="Exam End Date"
            md-sort-by="exam_end_date"
            style="width: 10%"
          >
            {{ item.exam_end_date }}
          </md-table-cell>

          <md-table-cell
            md-label="Exam Taken At"
            md-sort-by="taken_at"
            style="width: 25%"
          >
            {{ item.taken_at }}
          </md-table-cell>

          <md-table-cell md-label="Action" style="width: 10%">
            <base-button
              icon
              round
              class="btn-primary mr-1"
              title="Reset Exam Taken Flag  "
              @click="unblockUserExam(item.id)"
            >
              <i class="tim-icons icon-link-72"></i>
            </base-button>
          </md-table-cell>
        </md-table-row>
      </md-table>

      <md-chip
        class="md-primary mt-3"
        md-clickable
        @click="fetchActiveUserExam"
      >
        <i class="tim-icons icon-refresh-02"></i>
        Reload Data
      </md-chip>
    </div>
  </div>
</template>

<script>
const toLower = (text) => {
  return text.toString().toLowerCase();
};
const searchByName = (items, term) => {
  if (term) {
    return items.filter(
      (item) =>
        toLower(item.user_name).includes(toLower(term)) ||
        toLower(item.user_email).includes(toLower(term))
    );
  }

  return items;
};
export default {
  name: "unblock-user-exam",
  components: {},
  data() {
    return {
      currentSort: "name",
      currentSortOrder: "asc",
      searched: [],
      search: null,
      active_user_exams: [],
    };
  },
  created() {
    // fetch all active user exam data from database
    this.fetchActiveUserExam();
  },
  methods: {
    fetchActiveUserExam() {
      this.apiGet("fetchActiveUserExam")
        .then((response) => {
          this.active_user_exams = response.data;
          this.searched = this.active_user_exams;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    unblockUserExam(user_exam_id) {
      this.$swal({
        title: "Are You Sure?",
        text:
          "User exam will be unblocked. Are you sure?",
        icon: "question",
        showCancelButton: true,
      }).then((result) => {
        if (result.value) {
          this.apiPut("unblockUserExam/" + user_exam_id).then((response) => {
            if (response.status == 200) {
              this.showSuccessMessage(response.data.message);

              // reload active user exam list
              this.fetchActiveUserExam();
            }
          });
        }
      });
    },
    searchOnTable() {
      this.searched = searchByName(this.active_user_exams, this.search);
    },
    customSort(value) {
      return value.sort((a, b) => {
        const sortBy = this.currentSort;

        if (this.currentSortOrder === "desc") {
          return a[sortBy].localeCompare(b[sortBy]);
        }

        return b[sortBy].localeCompare(a[sortBy]);
      });
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-6"},[_c('card',{staticStyle:{"color":"#b596ad","min-height":"180px"}},[_c('h3',{staticStyle:{"margin-bottom":"5px"}},[_vm._v("Generate User's Score From Exam Period")]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12"},[_c('md-field',{staticStyle:{"margin-top":"0px","margin-bottom":"0px"}},[_c('label',[_vm._v("Select Exam Period")]),_c('md-select',{attrs:{"disabled":(_vm.examsList.length <= 0),"title":"If this field is disabled, then recent exam is not found."},model:{value:(_vm.selectedExam),callback:function ($$v) {_vm.selectedExam=$$v},expression:"selectedExam"}},_vm._l((_vm.examsList),function(examPeriod,index){return _c('md-option',{key:index,attrs:{"value":examPeriod.id}},[_vm._v(_vm._s(examPeriod.start_date + ' - ' + examPeriod.end_date)+" "+_vm._s(((examPeriod.free_exam)? '(FREE)': '(REG)')))])}),1)],1)],1)]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12 text-center mt-3"},[_c('md-button',{staticClass:"md-raised md-accent",on:{"click":_vm.generateUserExamScoreReport}},[_c('i',{staticClass:"tim-icons icon-paper"}),_vm._v(" Generate Exam Score Report ")])],1)])])],1),_vm._m(0)]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12"},[_c('md-table',{staticClass:"main-table",attrs:{"md-sort":_vm.currentSort,"md-sort-order":_vm.currentSortOrder,"md-sort-fn":_vm.customSort,"md-card":""},on:{"update:mdSort":function($event){_vm.currentSort=$event},"update:md-sort":function($event){_vm.currentSort=$event},"update:mdSortOrder":function($event){_vm.currentSortOrder=$event},"update:md-sort-order":function($event){_vm.currentSortOrder=$event}},scopedSlots:_vm._u([{key:"md-table-row",fn:function(ref){
var item = ref.item;
return _c('md-table-row',{},[_c('md-table-cell',{staticStyle:{"width":"30%"},attrs:{"md-label":"Nama Peserta","md-sort-by":"student_name"}},[_vm._v(" "+_vm._s(item.student_name)+" ")]),_c('md-table-cell',{staticStyle:{"width":"30%"},attrs:{"md-label":"Universitas Asal"}},[_vm._v(" "+_vm._s(item.origin_university)+" ")]),_c('md-table-cell',{staticStyle:{"width":"10"},attrs:{"md-label":"Angkatan"}},[_vm._v(" "+_vm._s(item.student_generation)+" ")]),_c('md-table-cell',{staticStyle:{"width":"20%"},attrs:{"md-label":"No. Telp"}},[_vm._v(" "+_vm._s(item.phone_number)+" ")]),_c('md-table-cell',{staticStyle:{"width":"10%"},attrs:{"md-label":"Score","md-sort-by":"exam_score"}},[_vm._v(" "+_vm._s(item.exam_score)+" ")])],1)}}]),model:{value:(_vm.searched),callback:function ($$v) {_vm.searched=$$v},expression:"searched"}},[_c('md-table-toolbar',[_c('h3',{staticClass:"md-title"},[_vm._v("User's Exam Score List")]),_c('div',{staticClass:"col-md-4"},[_c('md-field',{staticClass:"md-toolbar-section-end",attrs:{"md-clearable":""}},[_c('i',{staticClass:"tim-icons icon-zoom-split",staticStyle:{"margin-right":"10px"}}),_c('md-input',{attrs:{"placeholder":"Search by user's name..."},on:{"input":_vm.searchOnTable},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)],1)]),_c('md-table-empty-state',{attrs:{"md-label":"No User Exam Score To Be Displayed","md-description":"Select one of recent exams and click generate to fetch the data."}})],1)],1),_c('div',{staticClass:"col-12"},[_c('download-excel',{staticClass:"mt-3",staticStyle:{"float":"right"},attrs:{"fetch":_vm.fetchUserExamScore,"export-fields":{
            nama_peserta: 'student_name',
            universitas_asal: 'origin_university',
            angkatan: 'student_generation', 
            no_telp: 'phone_number', 
            line_id: 'line_id',
            nilai_ujian: 'exam_score'
          }}},[_c('md-chip',{staticClass:"md-accent",attrs:{"md-clickable":""}},[_c('i',{staticClass:"tim-icons icon-cloud-download-93"}),_vm._v(" Export Registered User To Excel ")])],1)],1)])])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"col-md-6"},[_c('div',{staticStyle:{"background-color":"#b00202","border-radius":"5px","padding":"15px 15px 15px 15px","border-left":"8px solid #7a0101","color":"whitesmoke","-moz-user-select":"none","-khtml-user-select":"none","-webkit-user-select":"none","-ms-user-select":"none","user-select":"none","min-height":"180px"}},[_c('h3',{staticStyle:{"border-bottom":"2px solid #7a0101","margin-bottom":"10px"}},[_vm._v(" Catatan ")]),_c('ul',{staticStyle:{"margin-left":"-20px"}},[_c('li',[_vm._v(" Digunakan untuk menarik data laporan nilai ujian per user berdasarkan periode tertentu. ")]),_c('li',[_vm._v(" Untuk generate laporan harus memilih periode aktif yang sesuai terlebih dahulu kemudian tekan generate. ")])])])])}]

export { render, staticRenderFns }
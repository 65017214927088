<template>
  <div class="row">
    <div class="col-12">
      <div class="row">
        <div class="col-md-6">
          <card style="color: #b596ad; min-height: 180px;">
            <h3 style="margin-bottom: 5px">Generate User's Score From Exam Period</h3>
            <div class="row">
              <div class="col-12">
                <md-field style="margin-top: 0px; margin-bottom: 0px">
                <label>Select Exam Period</label>
                <md-select 
                  v-model="selectedExam"
                  :disabled="(examsList.length <= 0)"
                  title="If this field is disabled, then recent exam is not found."
                >
                  <md-option
                    v-for="(examPeriod, index) in examsList"
                    :key="index"
                    :value="examPeriod.id"
                    >{{ examPeriod.start_date + ' - ' + examPeriod.end_date }} {{ ((examPeriod.free_exam)? '(FREE)': '(REG)') }}</md-option
                  >
                </md-select>
              </md-field>
              </div>
            </div>
            <div class="row">
              <div class="col-12 text-center mt-3">
                <md-button class="md-raised md-accent" @click="generateUserExamScoreReport">
                  <i class="tim-icons icon-paper"></i>&nbsp;Generate Exam Score Report
                </md-button>
              </div>
            </div>
          </card>
        </div>

        <div class="col-md-6">
          <div
            style="
              background-color: #b00202;
              border-radius: 5px;
              padding: 15px 15px 15px 15px;
              border-left: 8px solid #7a0101;
              color: whitesmoke;
              -moz-user-select: none;
              -khtml-user-select: none;
              -webkit-user-select: none;
              -ms-user-select: none;
              user-select: none;
              min-height: 180px;
            "
          >
            <h3 style="border-bottom: 2px solid #7a0101; margin-bottom: 10px">
              Catatan
            </h3>

            <ul style="margin-left: -20px">
              <li>
                Digunakan untuk menarik data laporan nilai ujian per user berdasarkan periode tertentu.
              </li>
              <li>
                Untuk generate laporan harus memilih periode aktif yang sesuai terlebih dahulu kemudian tekan generate.
              </li>
            </ul>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-12">
          <md-table
            class="main-table"
            v-model="searched"
            :md-sort.sync="currentSort"
            :md-sort-order.sync="currentSortOrder"
            :md-sort-fn="customSort"
            md-card
          >
            <md-table-toolbar>
              <h3 class="md-title">User's Exam Score List</h3>
              <div class="col-md-4">
                <md-field md-clearable class="md-toolbar-section-end">
                  <i
                    class="tim-icons icon-zoom-split"
                    style="margin-right: 10px"
                  ></i>
                  <md-input
                    placeholder="Search by user's name..."
                    v-model="search"
                    @input="searchOnTable"
                  />
                </md-field>
              </div>
            </md-table-toolbar>

            <md-table-empty-state
              md-label="No User Exam Score To Be Displayed"
              :md-description="`Select one of recent exams and click generate to fetch the data.`"
            >
            </md-table-empty-state>

            <md-table-row slot="md-table-row" slot-scope="{ item }">
              <md-table-cell
                md-label="Nama Peserta"
                md-sort-by="student_name"
                style="width: 30%;"
              >
                {{ item.student_name }}
              </md-table-cell>
              
              <md-table-cell
                md-label="Universitas Asal"
                style="width: 30%;"
              >
                {{ item.origin_university }}
              </md-table-cell>

              <md-table-cell
                md-label="Angkatan"
                style="width: 10;"
              >
                {{ item.student_generation }}
              </md-table-cell>

              <md-table-cell
                md-label="No. Telp"
                style="width: 20%;"
              >
                {{ item.phone_number }}
              </md-table-cell>

              <md-table-cell
                md-label="Score"
                md-sort-by="exam_score"
                style="width: 10%;"
              >
                {{ item.exam_score }}
              </md-table-cell>
            </md-table-row>
          </md-table>
        </div>

        <div class="col-12">
          <download-excel
            class="mt-3"
            style="float: right"
            :fetch="fetchUserExamScore"
            :export-fields="{
              nama_peserta: 'student_name',
              universitas_asal: 'origin_university',
              angkatan: 'student_generation', 
              no_telp: 'phone_number', 
              line_id: 'line_id',
              nilai_ujian: 'exam_score'
            }"
          >
            <md-chip class="md-accent" md-clickable>
              <i class="tim-icons icon-cloud-download-93"></i>
              Export Registered User To Excel
            </md-chip>
          </download-excel>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
const toLower = (text) => {
  return text.toString().toLowerCase();
};
const searchByName = (items, term) => {
  if (term) {
    return items.filter(
      (item) =>
        toLower(item.student_name).includes(toLower(term))
    );
  }

  return items;
};

export default {
  name: "user_exam_score_report", 
  data() {
    return {
      testVar: "test.",
      selectedExam: null, 
      examsList: [],
      userExamScoreList: [], 
      currentSort: "name",
      currentSortOrder: "asc",
      searched: [],
      search: null,
      tableKey: 0,
    }
  }, 
  created() {
    window.aku = this;
    // immediately load all options for period selection
    this.loadRecentPeriods();
  }, 
  methods: {
    loadRecentPeriods() {
      console.log('load recent periods');
      this.apiGet("getAllExamPeriod")
        .then((response) => {
          console.log(response);
          this.examsList = response.data;
        })
        .catch((error) => {
          console.log(error);
        })
    },

    searchOnTable() {
      this.searched = searchByName(this.userExamScoreList, this.search);
    },

    generateUserExamScoreReport() {

      // do validation for selected period
      if (!this.selectedExam) {
        this.showErrorWithCustomName("Exam Period");
        return;
      }

      // after validation succeed then continue with generation of report
      // data will be fetched via API
      this.apiGet("generateUserExamScore/" + this.selectedExam)
        .then((response) => {
          console.log(response.data);

          this.userExamScoreList = response.data;
          this.searched = this.userExamScoreList;
        })
        .catch((error) => {
          console.log(error);
        })
    },

    async fetchUserExamScore() {
      var apiResponse = await this.apiGet("generateUserExamScore/" + this.selectedExam);
      return apiResponse.data;
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
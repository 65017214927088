<template>
  <div class="row">
    <div class="col-md-12">
      <md-table
        v-model="period_list"
        :md-sort.sync="currentSort"
        :md-sort-order.sync="currentSortOrder"
        :md-sort-fn="customSort"
        md-card
      >
        <md-table-toolbar>
          <h3 class="md-title">Tutoring Period List</h3>
        </md-table-toolbar>

        <md-table-empty-state
          md-label="No Period Available"
          :md-description="`Create new period to make that appears on this list.`"
        >
        </md-table-empty-state>

        <md-table-row slot="md-table-row" slot-scope="{ item, index }">
          <md-table-cell md-label="No." width="5%">{{
            index + 1
          }}</md-table-cell>
          <md-table-cell md-label="Start Period" md-sort-by="start_date">{{
            formatDate(item.start_date)
          }}</md-table-cell>
          <md-table-cell md-label="End Period" md-sort-by="end_date">{{
            formatDate(item.end_date)
          }}</md-table-cell>
          <md-table-cell md-label="Attachment" md-sort-by="file_name">
            <a
              target="_blank"
              v-bind:href="
                backend_root + '/files/' +
                item.file_name
              "
            >
              Open Transfer Code Attachment
            </a>
          </md-table-cell>
          <md-table-cell md-label="Action">
            <base-button
              icon
              round
              class="btn-primary mr-1"
              title="Open User Registration"
              @click="openUserRegistration(item.id)"
            >
              <i class="tim-icons icon-notes"></i>
            </base-button>
            <base-button
              icon
              round
              class="btn-danger"
              title="Delete Period"
              @click="deletePeriod(item.id)"
            >
              <i class="tim-icons icon-trash-simple"></i>
            </base-button>
          </md-table-cell>
        </md-table-row>
      </md-table>
    </div>
  </div>
</template>

<script>
export default {
  name: "manage-period",
  components: {},
  data() {
    return {
      period_list: [],
      currentSort: "name",
      currentSortOrder: "asc",
    };
  },
  created() {
    // call get value function
    this.getAllPeriods();
  },
  methods: {
    getAllPeriods() {
      // active period GET request to API
      this.apiGet("getAllPeriod")
        .then((response) => {
          this.period_list = response.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    customSort(value) {
      return value.sort((a, b) => {
        const sortBy = this.currentSort;

        if (this.currentSortOrder === "desc") {
          return a[sortBy].localeCompare(b[sortBy]);
        }

        return b[sortBy].localeCompare(a[sortBy]);
      });
    },
    openUserRegistration(id) {
      this.$router.push("/manage-period/" + id);
    },
    deletePeriod(id) {
      // deleting period is considered dangerous action
      // make user confirm their action
      this.$swal({
        title: "Are You Sure?",
        text:
          "This period and user registration data will be deleted permanently.",
        icon: "question",
        showCancelButton: true,
      }).then((result) => {
        // user confirmed update profile action
        if (result.value) {
          // user confirmed the action,
          // proceed with API delete request
          this.apiDelete("tutoringPeriod/" + id)
            .then((response) => {
              // show delete success message
              this.showSuccessMessage(response.data.message);
              this.getAllPeriods();
            })
            .catch((error) => {
              this.showApiErrorMessage(
                "Server error! cannot delete tutoring period. Some users already registered to this period."
              );
            });
        }
      });
    },
  },
};
</script>

<style scoped>
</style>

<template>
  <div class="row">
    <div class="col-md-12">

      <div class="row" style="margin-top: -10px;">
        <div class="col-md-12">
          <h3 style="margin-bottom: 10px;">
            Tutoring Period Module
          </h3>
        </div>
        <router-card
          colSize="6"
          title="Period Sub-module"
          cardBody="Create New Period"
          description="Create/open tutoring period based on date range." 
          goToRoute="master-period"
        />
        <router-card
          colSize="6"
          title="Period Sub-module"
          cardBody="Manage Period"
          description="Manage all period(s) on Sejawat Website system." 
          imageName="bussiness-analysis.jpg"
          goToRoute="manage-period"
        />
      </div>
      
      <div class="row">
        <div class="col-md-12">
          <h3 style="margin-bottom: 10px;">
            National Examination Module
          </h3>
        </div>
        <router-card
          colSize="6"
          title="National Exam Sub-module"
          cardBody="Create New Question(s)"
          description="Create one to multiple national exam questions with additional image option." 
          imageName="new-question.png"
          goToRoute="master-questions"
        />
        <router-card
          colSize="6"
          title="National Exam Sub-module"
          cardBody="Manage Questions"
          description="Manage all national exam question(s) on Sejawat Website system." 
          imageName="data-update.png"
          goToRoute="manage-questions"
        />
        
        <router-card
          title="National Exam Sub-module"
          cardBody="Create New Exam"
          description="Create new exam regular/free with auto generated questions list." 
          imageName="new-exam.png"
          goToRoute="master-exam"
        />
        <router-card
          title="National Exam Sub-module"
          cardBody="Manage Exam"
          description="Show exams list, evaluate questions to each exam, or delete/close exam." 
          imageName="data-master-thumb.jpg"
          goToRoute="manage-exam"
        />
        <router-card
          title="National Exam Sub-module"
          cardBody="Unblock User Exam"
          description="To unlock user's exam restraint when user commited an accidental action when doing exam." 
          imageName="reset-password-thumb.png"
          goToRoute="unblock-user-exam"
        />
      </div>

      <div class="row">
        <div class="col-md-12">
          <h3 style="margin-bottom: 10px;">
            Reporting Module
          </h3>
        </div>
        <router-card
          title="User Report Sub-module"
          cardBody="User Examination Score"
          description="To show comprehensive list of user exam score per period." 
          imageName="report-thumb.jpg"
          goToRoute="user-exam-score-report"
        />
      </div>

      <!-- <div class="row">
        <div class="col-md-12">
          <h3 style="margin-bottom: 10px;">
            Student Comprehension Module
          </h3>
        </div>
        <router-card
          colSize="6"
          title="Test Component"
          cardBody="Test Router Card Body"
          description="test description" 
          imageName="bussiness-analysis.jpg"
          goToRoute="custom-route"
        />
      </div> -->
    </div>
  </div>
</template>

<script>
import RouterCard from '../components/Cards/RouterCard.vue';
export default {
  name: "modules-list", 
  components: {
    RouterCard,
  },
  data() {
    return {

    }
  }, 
  created() {

  }, 
  methods: {
    
  }
}
</script>

<style>

</style>
<template>
  <div class="row">
    <div class="col-md-4">
      <div
        class="card text-white card-has-bg click-col"
        style="background-image: url('img/collab-thumb1.jpg')"
      >
        <img class="card-img d-none" />
        <div class="card-img-overlay d-flex flex-column">
          <div class="card-body">
            <small class="card-meta mb-2">Compre Sub-module</small>
            <h4 class="card-title mt-0">
              <a class="text-white" href="#"> Do something here </a>
            </h4>
            <small><i class="fa fa-info"></i> October 15, 2020</small>
          </div>
        </div>
      </div>
    </div>

    <div class="col-md-4">
      <div
        class="card text-white card-has-bg click-col"
        style="background-image: url('img/search-thumb.jpg')"
      >
        <img class="card-img d-none" />
        <div class="card-img-overlay d-flex flex-column">
          <div class="card-body">
            <small class="card-meta mb-2">Compre Sub-module</small>
            <h4 class="card-title mt-0">
              <a class="text-white" href="#"> Do something here </a>
            </h4>
            <small><i class="far fa-clock"></i> October 15, 2020</small>
          </div>
        </div>
      </div>
    </div>

    <div class="col-md-4">
      <div
        class="card text-white card-has-bg click-col"
        style="background-image: url('img/build-schema1.png')"
      >
        <img class="card-img d-none" />
        <div class="card-img-overlay d-flex flex-column">
          <div class="card-body">
            <small class="card-meta mb-2">Compre Sub-module</small>
            <h4 class="card-title mt-0">
              <a class="text-white" href="#"> Do something here </a>
            </h4>
            <small><i class="far fa-clock"></i> October 15, 2020</small>
          </div>
        </div>
      </div>
    </div>

    <div class="col-md-4">
      <div
        class="card text-white card-has-bg click-col"
        style="background-image: url('img/diagram-thumb.jpg')"
      >
        <img class="card-img d-none" />
        <div class="card-img-overlay d-flex flex-column">
          <div class="card-body">
            <small class="card-meta mb-2">Compre Sub-module</small>
            <h4 class="card-title mt-0">
              <a class="text-white" href="#"> Do something here </a>
            </h4>
            <small><i class="far fa-clock"></i> October 15, 2020</small>
          </div>
        </div>
      </div>
    </div>

    <div class="col-md-4">
      <div
        class="card text-white card-has-bg click-col"
        style="background-image: url('img/user-analisys-thumb.jpg')"
      >
        <img class="card-img d-none" />
        <div class="card-img-overlay d-flex flex-column">
          <div class="card-body">
            <small class="card-meta mb-2">Compre Sub-module</small>
            <h4 class="card-title mt-0">
              <a class="text-white" href="#"> Do something here </a>
            </h4>
            <small><i class="far fa-clock"></i> October 15, 2020</small>
          </div>
        </div>
      </div>
    </div>

    <div class="col-md-4">
      <div
        class="card text-white card-has-bg click-col"
        style="background-image: url('img/bussiness-analysis.jpg')"
      >
        <img class="card-img d-none" />
        <div class="card-img-overlay d-flex flex-column">
          <div class="card-body">
            <small class="card-meta mb-2">Compre Sub-module</small>
            <h4 class="card-title mt-0">
              <a class="text-white" href="#"> Do something here </a>
            </h4>
            <small><i class="far fa-clock"></i> October 15, 2020</small>
          </div>
        </div>
      </div>
    </div>

    <router-card
      title="Test Component"
      cardBody="Test Router Card Body"
      description="test description" 
      imageName="bussiness-analysis.jpg"
      goToRoute="custom-route"
    />

    <div class="col-12">
      <video width="320" height="240" controls controlsList="nodownload">
        <source
          src="http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/ForBiggerBlazes.mp4"
          type="video/mp4"
        />
      </video>
    </div>
  </div>
</template>

<script>
import RouterCard from "../../components/Cards/RouterCard.vue";
export default {
  name: "compre-module",
  components: {
    RouterCard,
  },
  data() {
    return {};
  },
  created() {},
  methods: {},
};
</script>

<style lang="scss" scoped>
//post card styles
.card {
  border: none;
  transition: all 500ms cubic-bezier(0.19, 1, 0.22, 1);
  overflow: hidden;
  border-radius: 10px;
  min-height: 150px;
  box-shadow: 0 0 12px 0 rgba(0, 0, 0, 0.2);

  @media (max-width: 768px) {
    min-height: 275px;
  }

  @media (max-width: 420px) {
    min-height: 275px;
  }

  &.card-has-bg {
    transition: all 500ms cubic-bezier(0.19, 1, 0.22, 1);
    background-size: 120%;
    background-repeat: no-repeat;
    background-position: center center;
    &:before {
      content: "";
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background: inherit;
      -webkit-filter: grayscale(0.2);
      -moz-filter: grayscale(20%);
      -ms-filter: grayscale(20%);
      -o-filter: grayscale(20%);
      filter: grayscale(20%);
    }

    &:hover {
      transform: scale(0.98);
      box-shadow: 0 0 5px -2px rgba(0, 0, 0, 0.3);
      background-size: 130%;
      transition: all 500ms cubic-bezier(0.19, 1, 0.22, 1);

      .card-img-overlay {
        transition: all 800ms cubic-bezier(0.19, 1, 0.22, 1);
        background: rgb(35, 79, 109);
        background: linear-gradient(
          0deg,
          rgba(4, 69, 114, 0.5) 0%,
          rgba(4, 69, 114, 1) 100%
        );
      }
    }
  }
  .card-footer {
    background: none;
    border-top: none;
    .media {
      img {
        border: solid 3px rgba(234, 95, 0, 0.3);
      }
    }
  }
  .card-meta {
    color: orange;
  }
  .card-body {
    transition: all 500ms cubic-bezier(0.19, 1, 0.22, 1);
  }
  &:hover {
    .card-body {
      // margin-top: 30px;
      transition: all 800ms cubic-bezier(0.19, 1, 0.22, 1);
    }
    cursor: pointer;
    transition: all 800ms cubic-bezier(0.19, 1, 0.22, 1);
  }
  .card-img-overlay {
    transition: all 800ms cubic-bezier(0.19, 1, 0.22, 1);
    background: rgb(35, 79, 109);
    background: linear-gradient(
      0deg,
      rgba(54, 109, 146, 0.379) 0%,
      rgba(69, 95, 113, 1) 100%
    );
  }
}
</style>
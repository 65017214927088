<template>
  <div class="row">
    <div class="col-md-12" v-if="$store.state.isAdmin">
      <div class="row">
        <div class="col-md-12">
          <card style="padding: 10px 15px 0px 15px; color: #b596ad">
            <center>
              <span style="font-size: 20pt">Welcome To Sejawat Online</span>
              <hr style="border: 1px solid #b596ad" />
              <span style="font-size: 16pt">{{
                $store.state.userData.userName
              }}</span>
            </center>
          </card>
        </div>
        <div class="col-md-6">
          <card type="chart">
            <template slot="header">
              <h4 class="card-category">Monthly Users Exam Score</h4>
              <h3 class="card-title">
                <i class="tim-icons icon-atom text-primary"></i> 
                Scores Shown In Average
              </h3>
            </template>
            <div class="chart-area" :key="examScoreAverageChart.key">
              <line-chart
                style="height: 100%"
                chart-id="purple-line-chart"
                :chart-data.sync="examScoreAverageChart.chartData"
                :gradient-colors="examScoreAverageChart.gradientColors"
                :gradient-stops="examScoreAverageChart.gradientStops"
                :extra-options="examScoreAverageChart.extraOptions"
              >
              </line-chart>
            </div>
          </card>
        </div>

        <div class="col-md-6">
          <card type="chart">
            <template slot="header">
              <h4 class="card-category">Monthly New Users Registered</h4>
              <h3 class="card-title">
                <i class="tim-icons icon-chart-bar-32 text-primary"></i> This
                Year Total: {{ newUserRegisteredSum }}
              </h3>
            </template>
            <div class="chart-area" :key="newUserRegistrationChart.key">
              <line-chart
                style="height: 100%"
                chart-id="purple-line-chart"
                :chart-data.sync="newUserRegistrationChart.chartData"
                :gradient-colors="newUserRegistrationChart.gradientColors"
                :gradient-stops="newUserRegistrationChart.gradientStops"
                :extra-options="newUserRegistrationChart.extraOptions"
              >
              </line-chart>
            </div>
          </card>
        </div>
      </div>
    </div>

    <div class="col-lg-12 col-md-12 col-xs-12" v-else>
      <card style="padding: 10px 15px 0px 15px; color: #b596ad">
        <center>
          <span style="font-size: 20pt">Welcome To Sejawat Online</span>
          <hr style="border: 1px solid #b596ad" />
          <span style="font-size: 16pt">{{
            $store.state.userData.userName
          }}</span>
        </center>
        <div class="row">
          <div class="col-md-12 mt-5 mb-5">
            <center>
              <img src="img/logo4_1.png" width="180vw" alt="" />
            </center>
          </div>
          <div class="col-md-12 text-center">
            <h4 style="color: #b596ad;">
              <i class="tim-icons icon-volume-98"></i>
              Selangkah Lagi Menjadi Sejawat
              <i class="tim-icons icon-user-run"></i>
            </h4>
          </div>
        </div>
      </card>
    </div>
  </div>
</template>

<script>
import Card from "../components/Cards/Card.vue";
import LineChart from "@/components/Charts/LineChart";
import * as chartConfigs from "@/components/Charts/config";
import config from "@/config";

export default {
  components: {
    Card,
    LineChart,
  },
  name: "dashboard",
  data() {
    return {
      examScoreAverageChart: {
        extraOptions: chartConfigs.monthlyUserExamScoreOptions,
        chartData: {
          labels: [
            "JAN",
            "FEB",
            "MAR",
            "APR",
            "MAY",
            "JUN",
            "JUL",
            "AUG",
            "SEP",
            "OCT",
            "NOV",
            "DEC",
          ],
          datasets: [
            {
              label: "Avg. Score",
              fill: true,
              borderColor: config.colors.primary,
              borderWidth: 2,
              borderDash: [],
              borderDashOffset: 0.0,
              pointBackgroundColor: config.colors.primary,
              pointBorderColor: "rgba(255,255,255,0)",
              pointHoverBackgroundColor: config.colors.primary,
              pointBorderWidth: 20,
              pointHoverRadius: 4,
              pointHoverBorderWidth: 15,
              pointRadius: 4,
              data: [],
            },
          ],
        },
        gradientColors: config.colors.primaryGradient,
        gradientStops: [1, 0.2, 0],
        key: 0
      },
      newUserRegistrationChart: {
        extraOptions: chartConfigs.monthlyNewUserOptions,
        chartData: {
          labels: [
            "JAN",
            "FEB",
            "MAR",
            "APR",
            "MAY",
            "JUN",
            "JUL",
            "AUG",
            "SEP",
            "OCT",
            "NOV",
            "DEC",
          ],
          datasets: [
            {
              label: "Data",
              fill: true,
              borderColor: config.colors.primary,
              borderWidth: 2,
              borderDash: [],
              borderDashOffset: 0.0,
              pointBackgroundColor: config.colors.primary,
              pointBorderColor: "rgba(255,255,255,0)",
              pointHoverBackgroundColor: config.colors.primary,
              pointBorderWidth: 20,
              pointHoverRadius: 4,
              pointHoverBorderWidth: 15,
              pointRadius: 4,
              data: [],
            },
          ],
        },
        gradientColors: config.colors.primaryGradient,
        gradientStops: [1, 0.2, 0],
        key: 0,
      },
      newUserRegisteredSum: 0,
    };
  },
  created() {
    let here = this;

    // check if current user is admin or not
    if (this.$store.state.isAdmin) {
      this.apiGet('monthlyNewRegisteredUsers').then((response) => {
        // remove unneccessary key inside array
        let arrNewRegisteredUsersCount = [];
        response.data.forEach(function (item) {
          arrNewRegisteredUsersCount.push(item.new_users);
          
          // accumulate each count into sum variable
          here.newUserRegisteredSum += item.new_users;
        });

        // set array of values into chart data store
        this.newUserRegistrationChart.chartData.datasets[0].data = arrNewRegisteredUsersCount;
        this.newUserRegistrationChart.key++;
      });

      this.apiGet("monthlyUserExamScore").then((response) => {
        let arrUserExamScoreAvg =  [];

        response.data.forEach(function (item) {
          arrUserExamScoreAvg.push(item.avg_exam_score);
        });

        this.examScoreAverageChart.chartData.datasets[0].data = arrUserExamScoreAvg;
        this.examScoreAverageChart.key++;
      });
    }
  },
  methods: {},
};
</script>
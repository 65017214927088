<template>
  <div class="row">
    <div class="col-md-12">
      <md-table
        v-model="searched"
        :md-sort.sync="currentSort"
        :md-sort-order.sync="currentSortOrder"
        :md-sort-fn="customSort"
        :key="tableKey"
        md-card
        style="font-size: 10pt;"
      >
        <md-table-toolbar>
          <h4 class="md-title">
            Tutoring Period:
            <i style="color: #ff5252">{{
              formatDate(period_data.start_date)
            }}</i>
            until
            <i style="color: #ff5252">{{ formatDate(period_data.end_date) }}</i>
          </h4>
          <div class="col-md-4">
            <md-field md-clearable class="md-toolbar-section-end">
              <i
                class="tim-icons icon-zoom-split"
                style="margin-right: 10px"
              ></i>
              <md-input
                placeholder="Search by full name..."
                v-model="search"
                @input="searchOnTable"
              />
            </md-field>
          </div>
        </md-table-toolbar>

        <md-table-empty-state
          md-label="No registered users found"
          :md-description="`No user registering for this period yet. Try refreshing this page or press F5`"
        >
        </md-table-empty-state>

        <md-table-row slot="md-table-row" slot-scope="{ item, index }">
          <md-table-cell md-label="No." style="width: 5%;">
            {{ index + 1 }}
          </md-table-cell>
          <md-table-cell
            md-label="Full Name"
            md-sort-by="full_name"
            style="width: 20%;"
          >
            {{ item.full_name }}
          </md-table-cell>
          <md-table-cell
            md-label="Origin University"
            md-sort-by="origin_university"
            style="width: 20%"
          >
            {{ item.origin_university }}
          </md-table-cell>
          <md-table-cell
            md-label="Tutoring System"
            md-sort-by="tutoring_system"
            style="width: 20%"
          >
            {{ item.tutoring_system }}
          </md-table-cell>
          <md-table-cell
            md-label="Subject & Bundle"
            md-sort-by="subject_and_bundle"
            style="width: 20%;"
          >
            <!-- {{ JSON.parse(item.subject_and_bundle).join(", ") }} -->
            <md-chip
              v-for="value in JSON.parse(item.subject_and_bundle)"
              md-static
              :key="value"
              style="margin: 2px 2px 2px 2px"
            >
              <small>{{ value }}</small>
            </md-chip>
          </md-table-cell>
          <md-table-cell md-label="Verification Image" style="width: 10%">
            <a
              target="_blank"
              title="Click to open image in new tab"
              v-bind:href="backend_root + '/files/' + item.verification_image"
            >
              <img
                v-bind:src="backend_root + '/files/' + item.verification_image"
                style="height: 75px"
                alt=""
              />
            </a>
          </md-table-cell>
          <md-table-cell md-label="Status" style="width: 5%;">
            <md-chip
              class="md-primary"
              md-clickable 
              v-if="item.approved_at !== null"
              v-bind:title="'Approved at: ' + formatDate(item.approved_at) + ', CLICK to revoke.'"
              style="background-color: #0ecc00;"
              @click="undoUserRegistrationApproval(item.id, item.full_name)"
            >
              Approved
            </md-chip>
            <base-button
              v-else
              icon
              round
              class="btn-primary mr-1"
              title="Verify this user registration"
              style="width: 5%"
              @click="approveUserRegistration(item.id, item.full_name)"
            >
              <i class="tim-icons icon-check-2"></i>
            </base-button>
          </md-table-cell>
        </md-table-row>
      </md-table>

      <md-chip
        class="md-primary mt-3"
        md-clickable
        @click="loadUserRegistrationList"
      >
        <i class="tim-icons icon-refresh-02"></i>
        Reload Data
      </md-chip>

      <md-chip
        class="md-primary mt-3"
        md-clickable
        style="background-color: maroon; margin-left: 10px;"
        @click="$router.push('/manage-period');"
      >
        <i class="tim-icons icon-double-left"></i>
        Back To Period List
      </md-chip>

      <download-excel
        class="mt-3"
        style="float: right"
        :fetch="fetchApprovedUser"
        :export-fields="{
          nama_lengkap: 'full_name',
          universitas_asal: 'origin_university',
          angkatan: 'student_generation',
          line_id: 'line_id',
          sistem_bimbel: 'tutoring_system',
          paket_yang_dipilih: 'subject_and_bundle',
          verifikasi_pembayaran: {
            field: 'verification_image', 
            callback: (value) => {
              return backend_root + '/files/' + value;
            }
          },
          direct_link: {
            field: 'verification_image', 
            callback: (value) => {
              return '=HYPERLINK(G2)';
            }
          }
        }"
      >
        <md-chip class="md-accent" md-clickable>
          <i class="tim-icons icon-cloud-download-93"></i>
          Export Registered User To Excel
        </md-chip>
      </download-excel>
    </div>
  </div>
</template>

<script>
const toLower = (text) => {
  return text.toString().toLowerCase();
};
const searchByName = (items, term) => {
  if (term) {
    return items.filter((item) =>
      toLower(item.full_name).includes(toLower(term))
    );
  }

  return items;
};
export default {
  name: "manage_period_registration",
  components: {},
  data() {
    return {
      currentSort: "id",
      currentSortOrder: "desc",
      searched: [],
      search: null,
      tableKey: 0,
      period_id: null,
      period_data: {
        start_date: "dd-Mon-yyyy",
        end_date: "dd-Mon-yyyy",
      },
      registered_user_list: [],
    };
  },
  created() {
    // at beginning of page rendered check url params
    // if param exist, set it to data store
    if (this.$route.params.id) {
      this.period_id = this.$route.params.id;

      this.loadUserRegistrationList();
    }
  },
  methods: {
    loadUserRegistrationList() {
      // after getting period ID, select period information from API

      this.apiGet("tutoringPeriod/" + this.period_id).then((response) => {
        this.period_data = response.data;
        this.tableKey++;

        // get period user list from API
        this.apiGet("periodUser/" + this.period_id).then((response) => {
          this.registered_user_list = response.data;

          // auto set searched to be equals with registered user list
          this.searched = this.registered_user_list;
          this.tableKey++;
        });
      });
    },
    customSort(value) {
      return value.sort((a, b) => {
        const sortBy = this.currentSort;

        if (this.currentSortOrder === "desc") {
          return a[sortBy].localeCompare(b[sortBy]);
        }

        return b[sortBy].localeCompare(a[sortBy]);
      });
    },
    searchOnTable() {
      this.searched = searchByName(this.registered_user_list, this.search);
    },
    approveUserRegistration(registrationId, userFullName) {
      // approval action needs confirmation
      // show confirmation message
      this.$swal({
        title: "Are You Sure?",
        text: "Make sure " + userFullName + "'s payment is valid.",
        icon: "question",
        showCancelButton: true,
      }).then((result) => {
        // user confirmed user registration approval action
        if (result.value) {
          // post approve action to API
          this.apiPost("registrationApproval", {
            registration_id: registrationId,
          })
            .then((response) => {
              // approval success, show success message
              this.showSuccessMessage(response.data.message);

              // reload user registration list table contents
              this.loadUserRegistrationList();
            })
            .catch((error) => {
              console.log(error);
            });
        }
      });
    },
    undoUserRegistrationApproval (registrationId, userFullName) {
      // revoking user registration needs user confirmation
      // show confirmation message
      this.$swal({
        title: "Are You Sure?",
        text: "Please confirm to revoke " + userFullName + "'s period registration?",
        icon: "question",
        showCancelButton: true,
      }).then((result) => {
        if (result.value) {
          // user confirmed the action
          // post revoke approval to API
          // post approve action to API
          this.apiPost("registrationApprovalRevoke", {
            registration_id: registrationId,
          })
            .then((response) => {
              // approval success, show success message
              this.showSuccessMessage(response.data.message);

              // reload user registration list table contents
              this.loadUserRegistrationList();
            })
            .catch((error) => {
              console.log(error);
            });
        }
      });
    },
    async fetchApprovedUser() {
      var apiResponse = await this.apiGet("approvedUser/" + this.period_id);
      return apiResponse.data;
    },
  },
};
</script>

<style>
</style>
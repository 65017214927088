<template>
  <div class="row" style="color: #b596ad">
    <div class="col-md-12">
      <div style="
          background-color: #b00202;
          border-radius: 5px;
          padding: 15px 15px 15px 15px;
          border-left: 8px solid #7a0101;
          color: whitesmoke;
          -moz-user-select: none;
          -khtml-user-select: none;
          -webkit-user-select: none;
          -ms-user-select: none;
          user-select: none;
        ">
        <h3 style="border-bottom: 2px solid #7a0101; margin-bottom: 10px">
          Catatan
        </h3>

        <ul style="margin-left: -20px">
          <li>
            Modul ini digunakan untuk menambahkan pertanyaan baru kedalam database
            soal atau bank soal dari bimbel Sejawat.
          </li>
          <li>
            Sistem input bersifat batch (bisa menginput beberapa data sekaligus).
            Namun, setiap batch hanya dapat menggunakan 1 kategori soal saja.
            Terdapat fitur gambar secara opsional juga untuk mengakomodasi soal yang
            sudah menggunakan gambar.
          </li>
          <li>
            Soal ujian dapat diisikan sepanjang mungkin sesuai kebutuhan. Perlu
            diperhatikan bahwa batch input membutuhkan resource yang cukup banyak
            pada server. Oleh sebab itu, disarankan maks 5-6 pertanyaan saja untuk
            masing-masing batch. JANGAN MELAKUKAN REFRESH halaman saat melakukan
            input batch karena form akan reset dari 0 kembali
          </li>
        </ul>
      </div>
    </div>
    <div class="col-md-12 mt-3">
      <card style="margin-bottom: 15px">
        <md-field style="margin-top: 0px; margin-bottom: 0px">
          <label>Question Category</label>
          <md-select v-model="selectedCategory">
            <md-option v-for="(category, index) in questionCategories" :key="index" :value="category">{{ category
            }}</md-option>
          </md-select>
        </md-field>
      </card>

      <div class="row">
        <div class="col-md-12">
          <md-card v-for="(question, index) in questionsList" :key="index" style="
              background-color: #27293d;
              margin-bottom: 15px;
              z-index: 0;
              color: ;
            ">
            <md-card-header>
              <md-card-header-text>
                <div class="md-title" style="font-size: 16pt">
                  New Question For Category: {{ selectedCategory }}
                </div>
              </md-card-header-text>

              <md-menu md-size="small" md-direction="bottom-end">
                <md-button class="md-icon-button" md-menu-trigger>
                  <md-icon>
                    <i class="tim-icons icon-settings-gear-63"></i>
                  </md-icon>
                </md-button>

                <md-menu-content>
                  <md-menu-item @click="deleteQuestion(index)">
                    <span>Delete This Question</span>
                    <md-icon>
                      <i class="tim-icons icon-trash-simple"></i>
                    </md-icon>
                  </md-menu-item>
                </md-menu-content>
              </md-menu>
            </md-card-header>

            <md-card-content>
              <div class="row">
                <div class="col-md-12 mb-2">
                  <md-field>
                    <label>Question Code</label>
                    <md-input v-model="question.questionCode" placeholder="Enter Question Code (for searching purpose)" />
                  </md-field>
                </div>
                <div class="col-md-12">
                  <md-field>
                    <label>Type Question Here</label>
                    <md-textarea v-model="question.questionText" />
                  </md-field>
                </div>
                <div class="col-md-12">
                  <md-radio v-for="(choice, index) in question.multipleChoices" :key="index" :value="choice.value"
                    v-model="question.correctAnswer" style="width: 100%">
                    <md-input class="form-control" v-model="choice.text" placeholder="Multiple choice option" style="
                        margin: 0px 0px 0px 0px;
                        border: 1px solid #aaaaaa;
                        width: 75vmin;
                      " />
                  </md-radio>
                </div>
                <div class="col-md-12 mt-3">
                  Correct Answer: {{ question.correctAnswer }}
                </div>
                <div class="col-md-12 mt-3">
                  <md-field>
                    <label>Answer Explanation</label>
                    <md-textarea v-model="question.explanation" />
                  </md-field>
                </div>
                <div class="col-md-12 mt-2">
                  <upload-field-single v-model="question.imageOption" />
                </div>
              </div>
            </md-card-content>
          </md-card>
        </div>
      </div>
    </div>
    <div class="col-md-12" style="position: fixed; bottom: 10px">
      <md-button class="md-fab" title="Add New Question Form" style="background-color: #007bff;" @click="addNewQuestion">
        <md-icon>
          <i class="tim-icons icon-simple-add"></i>
        </md-icon>
      </md-button>
      <md-button class="md-fab" title="Submit Question Forms" style="background-color: #28a745;"
        @click="submitNewQuestions">
        <md-icon>
          <i class="tim-icons icon-send"></i>
        </md-icon>
      </md-button>
    </div>
  </div>
</template>

<script>
import UploadFieldSingle from "../components/UploadFieldSingle.vue"
export default {
  name: "master_questions",
  components: { UploadFieldSingle },
  data() {
    return {
      questionCategories: [],
      selectedCategory: null,
      questionsList: [],
    }
  },
  created() {
    // get question list from API
    this.apiGet("getQuestionCategories")
      .then((response) => {
        this.questionCategories = JSON.parse(
          response.data.array_string.replace(/\\/g, "")
        )
      })
      .catch((error) => {
        console.log(error)
      })
  },
  methods: {
    addNewQuestion() {
      // check if there is any category selected
      if (this.selectedCategory === null) {
        // no category selected
        // show error message
        this.showErrorWithCustomTitleAndContent(
          "Invalid Input",
          "Please select the category first!"
        )
      } else {
        // category selected
        // continue with question generator
        this.questionsList.push({
          questionCode: null,
          questionText: null,
          correctAnswer: "none",
          answerExplanation: null,
          multipleChoices: [
            { value: 'A', text: null },
            { value: 'B', text: null },
            { value: 'C', text: null },
            { value: 'D', text: null },
            { value: 'E', text: null },
          ],
          imageOption: [],
        })
      }
    },
    deleteQuestion(index) {
      // deletion needs confirmation, show confirmation message
      this.$swal({
        title: "Are You Sure?",
        text: "Confirm to delete selected question.",
        icon: "question",
        showCancelButton: true,
      }).then((result) => {
        if (result.value) {
          // user confirmation acquired, proceed with data deletion
          this.questionsList.splice(index, 1)
        }
      })
    },
    submitNewQuestions() {
      // set validation flag variable
      let isValid = true

      // validate for empty question list
      if (this.questionsList.length <= 0) {
        isValid = false
        this.showErrorWithCustomTitleAndContent(
          "Invalid Input",
          "No question form to submit!"
        )
      }

      // validate question forms
      this.questionsList.forEach((data, index) => {
        // ADDED @ 06-08-2023 by Richard, custom request dari sejawat
        // first of all, validate question code
        if (!data.questionCode) {
          isValid = false
          this.showErrorWithCustomName(`No ${index + 1} question code`)
        }

        // next, validate the question text
        if (!data.questionText) {
          isValid = false
          this.showErrorWithCustomName(`No. ${index + 1} question text`)
        }

        // validate multiple choice options
        if (
          data.multipleChoices[0].text === null ||
          data.multipleChoices[0].text == "" ||
          data.multipleChoices[1].text === null ||
          data.multipleChoices[1].text == "" ||
          data.multipleChoices[2].text === null ||
          data.multipleChoices[2].text == "" ||
          data.multipleChoices[3].text === null ||
          data.multipleChoices[3].text == "" ||
          data.multipleChoices[4].text === null ||
          data.multipleChoices[4].text == ""
        ) {
          isValid = false
          this.showErrorWithCustomName(`No. ${index + 1} multiple choice`)
        }

        // validate selected answer
        if (!data.correctAnswer || data.correctAnswer == "") {
          isValid = false
          this.showErrorWithCustomName(`No. ${index + 1} correct answer`)
        }
      })

      if (isValid) {
        // submit needs user confirmation, show confirmation message
        this.$swal({
          title: "Are You Sure?",
          text:
            "Make sure every question is valid. Confirm to submit new questions.",
          icon: "question",
          showCancelButton: true,
        }).then((result) => {
          if (result.value) {
            // user confirmation aqcuired, proceed with API post data
            // but first, we need to form the data body using formdata
            let formData = new FormData()
            let dataCount = 0

            // iterate through every question
            this.questionsList.forEach((item, index) => {
              formData.append(`question_code[${index}]`, item.questionCode)
              formData.append(`question_category[${index}]`, this.selectedCategory)
              formData.append(`question_text[${index}]`, item.questionText)
              formData.append(`option1[${index}]`, item.multipleChoices[0].text)
              formData.append(`option2[${index}]`, item.multipleChoices[1].text)
              formData.append(`option3[${index}]`, item.multipleChoices[2].text)
              formData.append(`option4[${index}]`, item.multipleChoices[3].text)
              formData.append(`option5[${index}]`, item.multipleChoices[4].text)
              formData.append(`correct_answer[${index}]`, item.correctAnswer)
              formData.append(`explanation[${index}]`, item.explanation)

              var optionalFile =
                item.imageOption.length <= 0 ? null : item.imageOption[0].file

              formData.append(`image_file[${index}]`, optionalFile)

              dataCount++
            })

            formData.append("data_count", dataCount)

            // call api using multipart content type
            this.apiPostMultipart("submitQuestions", formData)
              .then((response) => {
                // show success message
                this.showSuccessMessage(response.data.message)

                // clear questions list form
                this.selectedCategory = null
                this.questionsList = []
              })
              .catch((errpr) => {
                console.log(error)
              })
          }
        })
      }
    },
  },
}
</script>

<style scoped>
.md-radio {
  display: flex;
}
</style>
<template>
  <div class="row">
    <div class="col-md-12">
      <md-table v-model="exams_list" :md-sort.sync="currentSort" :md-sort-order.sync="currentSortOrder"
        :md-sort-fn="customSort" md-card>
        <md-table-toolbar>
          <h3 class="md-title">Exams List</h3>
        </md-table-toolbar>

        <md-table-empty-state md-label="No Exams Available"
          :md-description="`Create new exam to make that appears on this list.`">
        </md-table-empty-state>

        <md-table-row slot="md-table-row" slot-scope="{ item, index }">
          <md-table-cell md-label="No." width="5%">{{
            index + 1
          }}</md-table-cell>
          <md-table-cell md-label="Start Date" md-sort-by="start_date">{{
            formatDate(item.start_date)
          }}</md-table-cell>
          <md-table-cell md-label="End Date" md-sort-by="end_date">{{
            formatDate(item.end_date)
          }}</md-table-cell>
          <md-table-cell md-label="Free Exam">
            {{ item.free_exam ? "YES" : "NO" }}
          </md-table-cell>
          <md-table-cell md-label="Total Questions">
            {{ item.total_questions }} questions
          </md-table-cell>
          <md-table-cell md-label="Duration">
            {{ item.duration }} mins.
          </md-table-cell>
          <md-table-cell md-label="Action">
            <base-button icon round class="btn-primary mr-1" title="Show Questions List"
              @click="showQuestionsList(item.id, index)">
              <i class="tim-icons icon-zoom-split"></i>
            </base-button>
            <base-button icon round class="btn-danger" title="Delete Period" @click="deleteMasterExam(item.id)">
              <i class="tim-icons icon-trash-simple"></i>
            </base-button>
          </md-table-cell>
        </md-table-row>
      </md-table>

      <md-dialog :md-active.sync="showModal" :md-click-outside-to-close="false">
        <md-dialog-title> Exam Questions List </md-dialog-title>

        <md-dialog-content>
          <div class="row">
            <div class="col-md-12">
              <div class="row" v-for="(question, index) in questions_list" :key="question.id">
                <div class="col-md-12">
                  <b>No. {{ index + 1 }}</b>
                  <br />
                  Question Category: <i>{{ question.question_category }}</i>
                </div>
                <div class="col-md-12" style="text-align: justify">
                  {{ question.question_text }}
                </div>
                <div class="col-md-12">
                  <ol type="A" style="margin-left: -23px; margin-bottom: 0px;">
                    <li v-if="question.correct_answer == 'A'" style="font-weight: bold; color: orange">
                      {{ question.option1 }}
                    </li>
                    <li v-else>{{ question.option1 }}</li>
                    <li v-if="question.correct_answer == 'B'" style="font-weight: bold; color: orange">
                      {{ question.option2 }}
                    </li>
                    <li v-else>{{ question.option2 }}</li>
                    <li v-if="question.correct_answer == 'C'" style="font-weight: bold; color: orange">
                      {{ question.option3 }}
                    </li>
                    <li v-else>{{ question.option3 }}</li>
                    <li v-if="question.correct_answer == 'D'" style="font-weight: bold; color: orange">
                      {{ question.option4 }}
                    </li>
                    <li v-else>{{ question.option4 }}</li>
                    <li v-if="question.correct_answer == 'E'" style="font-weight: bold; color: orange">
                      {{ question.option5 }}
                    </li>
                    <li v-else>{{ question.option5 }}</li>
                  </ol>
                </div>
                <div class="col-md-12 mt-1 mb-2">
                  <b>Penjelasan No. {{ index + 1 }}: </b>
                  {{ (question.explanation) ? question.explanation : '- (belum ada penjelasan tambahan)' }}
                </div>
              </div>
            </div>
          </div>
        </md-dialog-content>

        <md-dialog-actions>
          <md-button class="md-raised md-accent" @click="showModal = false">
            <i class="tim-icons icon-simple-remove" style="margin-top: -2px"></i>
            Close
          </md-button>
        </md-dialog-actions>
      </md-dialog>
    </div>
  </div>
</template>

<script>
export default {
  name: "manage-exam",
  data() {
    return {
      exams_list: [],
      questions_list: [],
      showModal: false,
      currentSort: "name",
      currentSortOrder: "asc",
    }
  },
  created() {
    window.aku = this

    // fetch all of the exam periods
    this.fetchAllExamPeriods()
  },
  methods: {
    fetchAllExamPeriods() {
      this.apiGet("getAllExamPeriod")
        .then((response) => {
          this.exams_list = response.data
        })
        .catch((error) => {
          console.log(error)
        })
    },
    customSort(value) {
      return value.sort((a, b) => {
        const sortBy = this.currentSort

        if (this.currentSortOrder === "desc") {
          return a[sortBy].localeCompare(b[sortBy])
        }

        return b[sortBy].localeCompare(a[sortBy])
      })
    },
    showQuestionsList(id, index) {
      // console.log(this.exams_list[index]);

      this.apiGet("getExamQuestions/" + id)
        .then((response) => {
          this.questions_list = response.data
          this.showModal = true
        })
        .catch((error) => {
          console.log(error)
        })
    },
    deleteMasterExam(id) {
      // deleting exam requires confirmation
      // show confirmation message
      this.$swal({
        title: "Are You Sure?",
        text: "Exam period will be deleted permanently.",
        icon: "question",
        showCancelButton: true,
      }).then((result) => {
        if (result.value) {
          // send delete request to API
          this.apiDelete("deleteExamPeriod/" + id)
            .then((response) => {
              // api response OK, show success message
              this.showSuccessMessage(response.data.message)

              // reload all exam periods list
              this.fetchAllExamPeriods()
            })
            .catch((error) => {
              console.log(error)
            })
        }
      })
    },
  },
}
</script>

<style lang="scss" scoped>
::-webkit-scrollbar {
  width: 5px;
}

::-webkit-scrollbar-track {
  // background: #f1f1f1;
  background-color: transparent;
}

::-webkit-scrollbar-thumb {
  background-color: #fa7000;
  border-radius: 50px;
  // border: 6px solid transparent;
  background-clip: content-box;
}

::-webkit-scrollbar-thumb:hover {
  background: #fab300;
}
</style>
<template>
  <div class="row" style="color: #b596ad">
    <div class="col-md-12">
      <div class="row">
        <div class="col-md-12">
          <div style="
              background-color: #b00202;
              border-radius: 5px;
              padding: 15px 15px 15px 15px;
              border-left: 8px solid #7a0101;
              color: whitesmoke;
              -moz-user-select: none;
              -khtml-user-select: none;
              -webkit-user-select: none;
              -ms-user-select: none;
              user-select: none;
            ">
            <h3 style="border-bottom: 2px solid #7a0101; margin-bottom: 10px">
              Catatan
            </h3>

            <ul style="margin-left: -20px">
              <li>
                Modul ini digunakan untuk memperbarui pertanyaan soal yang sudah
                ada pada bank soal milik bimbel Sejawat.
              </li>
              <li>
                Field-field akan terisi secara otomatis sesuai dengan pertanyaan
                yang dipilih pada tabel list soal sebelum ini. Pengisian sama
                dengan pada saat menambahkan pertanyaan baru.
              </li>
              <li>
                Jika soal sudah mempunyai gambar, maka gambar pada soal tersebut
                tidak dapat dihapuskan (hanya dapat diubah).
              </li>
              <li>
                Untuk menghapus gambar pada suatu soal, maka soal tersebut harus
                dihapus terlebih dahulu dari sistem dan lakukan input kembali
                soal tersebut (tanpa gambar).
              </li>
            </ul>
          </div>
        </div>
      </div>
      <card class="mt-3">
        <div class="row mb-3">
          <div class="col-md-12">
            <md-field style="margin-bottom: 5px">
              <label>Question Code</label>
              <md-input v-model="questionData.question_code"></md-input>
            </md-field>
          </div>
        </div>
        <div class="row mt-3">
          <div class="col-md-12">
            <md-field style="margin-top: 0px; margin-bottom: 0px">
              <label>Question Category</label>
              <md-select v-model="questionData.question_category">
                <md-option v-for="(category, index) in questionCategories" :key="index" :value="category">{{ category
                }}</md-option>
              </md-select>
            </md-field>
          </div>
        </div>
        <div class="row mt-3">
          <div class="col-md-12">
            <md-field style="margin-bottom: 5px">
              <label>Type Question Here</label>
              <md-textarea v-model="questionData.question_text"></md-textarea>
            </md-field>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <md-radio v-model="questionData.correct_answer" value="A">
              <md-input class="form-control" v-model="questionData.option1" placeholder="Multiple choice option" style="
                  margin: 0px 0px 0px 0px;
                  border: 1px solid #aaaaaa;
                  width: 75vmin;
                " />
            </md-radio>
            <md-radio v-model="questionData.correct_answer" value="B">
              <md-input class="form-control" v-model="questionData.option2" placeholder="Multiple choice option" style="
                  margin: 0px 0px 0px 0px;
                  border: 1px solid #aaaaaa;
                  width: 75vmin;
                " />
            </md-radio>
            <md-radio v-model="questionData.correct_answer" value="C">
              <md-input class="form-control" v-model="questionData.option3" placeholder="Multiple choice option" style="
                  margin: 0px 0px 0px 0px;
                  border: 1px solid #aaaaaa;
                  width: 75vmin;
                " />
            </md-radio>
            <md-radio v-model="questionData.correct_answer" value="D">
              <md-input class="form-control" v-model="questionData.option4" placeholder="Multiple choice option" style="
                  margin: 0px 0px 0px 0px;
                  border: 1px solid #aaaaaa;
                  width: 75vmin;
                " />
            </md-radio>
            <md-radio v-model="questionData.correct_answer" value="E">
              <md-input class="form-control" v-model="questionData.option5" placeholder="Multiple choice option" style="
                  margin: 0px 0px 0px 0px;
                  border: 1px solid #aaaaaa;
                  width: 75vmin;
                " />
            </md-radio>
          </div>
        </div>

        <div class="row mt-3">
          <div class="col-md-12">
            Correct Answer: {{ questionData.correct_answer }}
          </div>
        </div>

        <div class="row mt-3" style="margin-top: -15px">
          <div class="col-md-12">
            <md-field style="margin-bottom: 5px">
              <label>Answer Explanation</label>
              <md-textarea v-model="questionData.explanation"></md-textarea>
            </md-field>
          </div>
        </div>

        <div class="row mt-3">
          <div class="col-md-12">
            <upload-field-single v-model="newImage" />
          </div>
        </div>

        <div class="row mt-3">
          <div class="col-md-6">
            <md-button class="md-raised md-primary" style="margin-left: 0px; margin-right: 0px; width: 100%"
              @click="saveUpdatedQuestion">
              <i class="tim-icons icon-upload"></i>&nbsp;Save
            </md-button>
          </div>
          <div class="col-md-6">
            <md-button class="md-raised md-accent" style="margin-left: 0px; margin-right: 0px; width: 100%"
              @click="backToQuestionsList">
              <i class="tim-icons icon-simple-remove"></i>&nbsp;Cancel
            </md-button>
          </div>
        </div>
      </card>
    </div>
  </div>
</template>

<script>
import UploadFieldSingle from "../components/UploadFieldSingle.vue"
export default {
  name: "manage-question-form",
  components: {
    UploadFieldSingle,
  },
  data() {
    return {
      id: null,
      questionCategories: [],
      questionData: {},
      newImage: [],
    }
  },
  created() {
    window.aku = this

    this.id = this.$route.params.id

    // load question data
    this.loadQuestionData()
  },
  methods: {
    loadQuestionData() {
      // use promise to get question categories
      const getQuestionCategories = () => this.apiGet("getQuestionCategories")
      const getQuestionData = (id) => this.apiGet("getQuestion/" + this.id)

      getQuestionCategories()
        .then((questionCategoriesResponse) => {
          this.questionCategories = JSON.parse(
            questionCategoriesResponse.data.array_string.replace(/\\/g, "")
          )
        })
        .then(() => {
          getQuestionData(this.id).then((questionDataResponse) => {
            this.questionData = questionDataResponse.data
          })
        })
    },
    saveUpdatedQuestion() {
      // do input validation
      // fields to validate:
      // - question text cannot be empty ot null
      // - answer options each cannot be empty
      // - selected answer cannot be empty
      let isValid = true

      // ADDED @  06-08-2023 by Richard, custom request upgrade question
      // validate new question code
      if (!this.questionData.question_code) {
        isValid = false
        this.showErrorWithCustomName("New question code")
      }

      // validate new question text
      if (!this.questionData.question_text) {
        isValid = false
        this.showErrorWithCustomName("New question text")
      }

      // validate each answer options, none is allowed to be null or empty
      if (
        this.questionData.option1 === null ||
        this.questionData.option1 == "" ||
        this.questionData.option2 === null ||
        this.questionData.option2 == "" ||
        this.questionData.option3 === null ||
        this.questionData.option3 == "" ||
        this.questionData.option4 === null ||
        this.questionData.option4 == "" ||
        this.questionData.option5 === null ||
        this.questionData.option5 == ""
      ) {
        isValid = false
        // show error message
        this.showErrorWithCustomName("All answer options")
      }

      // validate selected answer
      if (
        this.questionData.correct_answer === null ||
        this.questionData.correct_answer == ""
      ) {
        isValid = false
        // show error message
        this.showErrorWithCustomName("Correct answer")
      }

      // check validation
      if (isValid) {
        // save action needs user confirmation
        // show confirmation message
        this.$swal({
          title: "Are You Sure?",
          text: "Confirm to submit your updated question.",
          icon: "question",
          showCancelButton: true,
        }).then((result) => {
          if (result.value) {
            // use formdata because file upload requires request with multipart type
            let formData = new FormData()

            // chechk if image input has any file or not
            let imageFile =
              this.newImage.length <= 0 ? null : this.newImage[0].file

            // append formdata values based on existing form object values
            for (var key in this.questionData) {
              formData.append(key, this.questionData[key])
            }

            // append the image file option value
            // and append method type (DON'T ASK)
            formData.append("image_file", imageFile)
            formData.append("_method", "PUT")

            // post data using multipart option
            this.apiPostMultipart("updateQuestion/" + this.id, formData)
              .then((response) => {
                // api response OK, show success message
                this.showSuccessMessage(response.data.message)

                // redirect to questions list pages
                this.$router.push("/manage-questions")
              })
              .catch((error) => {
                this.showApiErrorMessage("Failed to update question.")
                console.log(error)
              })
          }
        })
      }
    },
    backToQuestionsList() {
      this.$router.push("/manage-questions")
    },
  },
}
</script>

<style scoped>
.md-radio {
  display: flex;
}
</style>
<template>
  <div class="row">
    <div class="col-md-12">
      <div class="row mb-3">
        <div class="col-md-12">
          <div
            style="
              background-color: #b00202;
              border-radius: 5px;
              padding: 15px 15px 15px 15px;
              border-left: 8px solid #7a0101;
              color: whitesmoke;
              -moz-user-select: none;
              -khtml-user-select: none;
              -webkit-user-select: none;
              -ms-user-select: none;
              user-select: none;
            "
          >
            <h3 style="border-bottom: 2px solid #7a0101; margin-bottom: 10px">
              Catatan
            </h3>

            Sebelum mengisi form untuk membuka pendaftaran bimbel baru, harap
            diperhatikan beberapa hal berikut ini:
            <ul
              style="margin-left: -15px; margin-bottom: 5px; font-style: italic"
            >
              <li>
                Tanggal awal periode direkomendasikan memilih tanggal pertama
                pada bulan tersebut. Misal: bulan Januari 2021, maka pilih
                tanggal: 01-JAN-2021
              </li>
              <li>
                Tanggal akhir periode direkomendasikan memilih tanggal terakhir
                pada bulan tersebut. Misal: bulan Februari 2021, maka pilih
                tanggal: 31-JAN-2021
              </li>
              <li>
                Kode transfer mengikuti Google Form dalam bentuk gambar yang
                dapat diupload pada form dibawah ini.
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div class="row mt-4">
        <div class="col-md-12">
          <card style="color: #b596ad">
            <h3 style="margin-bottom: 5px">Create New tutoring Period</h3>
            <div class="row">
              <div class="col-md-6">
                <md-datepicker
                  v-model="form.start_date"
                  placeholder="Start Date"
                  :md-immediately="true"
                >
                  <label>Select Start Date</label>
                </md-datepicker>
              </div>
              <div class="col-md-6">
                <md-datepicker
                  v-model="form.end_date"
                  placeholder="End Date"
                  :md-immediately="true"
                >
                  <label>Select End Date</label>
                </md-datepicker>
              </div>
              <div class="col-md-12">
                <VueFileAgent
                  ref="vueFileAgent"
                  v-model="fileRecords"
                  :theme="'list'"
                  :multiple="false"
                  :deletable="true"
                  :meta="true"
                  :accept="'image/*,.zip'"
                  :maxSize="'2MB'"
                  :helpText="'Choose transfer code image file (should be less than 2 MB)'"
                  :errorText="{
                    type: 'Invalid file type. Only images Allowed',
                    size: 'Files should not exceed 2MB in size',
                  }"
                  @select="filesSelected($event)"
                  @beforedelete="onBeforeDelete($event)"
                  @delete="fileDeleted($event)"
                />
              </div>
            </div>
          </card>
          <div class="row">
              <div class="col-md-12 text-center" style="margin-top: -15px;">
                <md-button
                  class="md-raised md-accent"
                  style="float: right; border-radius: 3px; margin-right: 0px"
                  @click="submit"
                >
                  <i class="tim-icons icon-send pr-2"></i>
                  Submit Tutoring Period
                </md-button>
              </div>
            </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "master_tutoring",
  data() {
    return {
      form: {
        start_date: null,
        end_date: null,
      },
      fileRecords: [],
      uploadUrl: "https://www.mocky.io/v2/5d4fb20b3000005c111099e3",
      uploadHeaders: { "X-Test-Header": "vue-file-agent" },
      fileRecordsForUpload: [],
    };
  },
  created() {},
  methods: {
    uploadFiles: function () {
      // Using the default uploader. You may use another uploader instead.
      this.$refs.vueFileAgent.upload(
        this.uploadUrl,
        this.uploadHeaders,
        this.fileRecordsForUpload
      );
      this.fileRecordsForUpload = [];
    },
    deleteUploadedFile: function (fileRecord) {
      // Using the default uploader. You may use another uploader instead.
      this.$refs.vueFileAgent.deleteUpload(
        this.uploadUrl,
        this.uploadHeaders,
        fileRecord
      );
    },
    selectFile(files) {
      this.form.transfer_code_attachment = fileToListArray;
    },
    filesSelected: function (fileRecordsNewlySelected) {
      var validFileRecords = fileRecordsNewlySelected.filter(
        (fileRecord) => !fileRecord.error
      );
      this.fileRecordsForUpload = this.fileRecordsForUpload.concat(
        validFileRecords
      );
    },
    onBeforeDelete: function (fileRecord) {
      var i = this.fileRecordsForUpload.indexOf(fileRecord);
      if (i !== -1) {
        // queued file, not yet uploaded. Just remove from the arrays
        this.fileRecordsForUpload.splice(i, 1);
        var k = this.fileRecords.indexOf(fileRecord);
        if (k !== -1) this.fileRecords.splice(k, 1);
      } else {
        if (confirm("Are you sure you want to delete?")) {
          this.$refs.vueFileAgent.deleteFileRecord(fileRecord); // will trigger 'delete' event
        }
      }
    },
    fileDeleted: function (fileRecord) {
      var i = this.fileRecordsForUpload.indexOf(fileRecord);
      if (i !== -1) {
        this.fileRecordsForUpload.splice(i, 1);
      } else {
        this.deleteUploadedFile(fileRecord);
      }
    },
    submit: function () {
      var isValid = true;

      // start input validation
      if (
        this.form.start_date === null ||
        this.form.start_date == "" ||
        this.form.start_date === undefined
      ) {
        isValid = false;
        this.$notify({
          title: "Input Invalid",
          message: "Start date field cannot be empty.",
          icon: "tim-icons icon-alert-circle-exc",
          horizontalAlign: "right",
          verticalAlign: "bottom",
          type: "info",
          timeout: 3000,
        });
      }

      if (
        this.form.end_date === null ||
        this.form.end_date == "" ||
        this.form.end_date === undefined
      ) {
        isValid = false;
        this.$notify({
          title: "Input Invalid",
          message: "End date field cannot be empty.",
          icon: "tim-icons icon-alert-circle-exc",
          horizontalAlign: "right",
          verticalAlign: "bottom",
          type: "info",
          timeout: 3000,
        });
      }

      if (this.fileRecordsForUpload.length <= 0) {
        isValid = false;
        this.$notify({
          title: "Input Invalid",
          message: "Upload field cannot be empty or it has invalid file.",
          icon: "tim-icons icon-alert-circle-exc",
          horizontalAlign: "right",
          verticalAlign: "bottom",
          type: "info",
          timeout: 3000,
        });
      }

      if (
        this.form.start_date !== null &&
        this.form.start_date !== undefined &&
        this.form.end_date !== null &&
        this.form.end_date !== undefined
      ) {

        if (this.form.start_date > this.form.end_date) {

          // input not valid
          isValid = false;
            
          // start date is greater than end date
          // show error message
          this.showErrorWithCustomTitleAndContent(
            "Cannot Backdate",
            "Start date should be less than end date"
          );
        }
      }

      // check if all inputs are valid, send data to API
      if (isValid) {
        // multipart using formdata
        var formData = new FormData();
        formData.append("start_date", this.form.start_date);
        formData.append("end_date", this.form.end_date);
        formData.append("file", this.fileRecordsForUpload[0].file);
        this.apiPostMultipart("tutoringPeriod", formData)
          .then((response) => {
            if (response.status == 200) {
              // show insert successful message
              this.$swal({
                title: "Success",
                text: response.data.message,
                icon: "success",
              });

              // reset form values
              this.form.start_date = null;
              this.form.end_date = null;
              this.fileRecords = [];
              this.fileRecordsForUpload = [];
            }
          })
          .catch((error) => {
            console.log(error);
          });
      }
    },
  },
};
</script>

<style>
</style>
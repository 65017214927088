<template>
  <div class="row">
    <div class="col-md-7">
      <card style="color: #b596ad">
        <div
          class="row"
          v-for="(subjectBundling, index) in SubjectBundlingList"
          v-bind:key="subjectBundling"
          style="padding: 0px 15px 0px 15px"
        >
          <md-chip
            class="md-success col-md-12 mb-2"
            md-deletable
            style="overflow: hidden; text-overflow: ellipsis"
            @click="selectSubjectBundling(index)"
            @keyup.prevent="moveSubjectBundlingIndex($event)"
            @md-delete="deleteSubjectBundlingItem(index)"
          >
            {{ subjectBundling }}
          </md-chip>
        </div>
        <md-divider class="mt-1"></md-divider>
        <div class="row mt-2">
          <div class="col-md-12" v-if="selectedIndex === null">
            <b><i>No row selected</i></b>
          </div>
          <div class="col-md-12" v-else>
            <b
              ><i>Selected Row: {{ SubjectBundlingList[selectedIndex] }}</i></b
            >
          </div>
        </div>
      </card>
    </div>
    <div class="col-md-5">
      <div
        style="
          background-color: #b00202;
          border-radius: 5px;
          padding: 15px 15px 15px 15px;
          border-left: 8px solid #7a0101;
          color: whitesmoke;
          -moz-user-select: none;
          -khtml-user-select: none;
          -webkit-user-select: none;
          -ms-user-select: none;
          user-select: none;
        "
      >
        <h3 style="border-bottom: 2px solid #7a0101; margin-bottom: 10px">
          Catatan
        </h3>

        <ul style="margin-left: -20px">
          <li>
            Subject bundling ini digunakan untuk melakukan penambahan atau
            update data materi dan paketan yang disediakan oleh bimbel sejawat.
          </li>
          <li>
            Tidak ada fitur update. Jika hendak mengganti materi/bundling maka
            harus hapus dahulu kemudian tambahkan data yang baru.
          </li>
          <li>
            untuk mengatur urutan materi/bundling pilih salah materi dan tekan
            tombol panah atas atau bawah pada keyboard.
          </li>
        </ul>
      </div>
      <card class="mt-3">
        <div class="row">
          <div class="col-md-12">
            <md-field>
              <label>New Pricing System</label>
              <md-input
                v-model="newSubjectBundling"
                placeholder="Create new subject bundling"
              ></md-input>
            </md-field>
          </div>
          <div class="col-md-6">
            <md-button
              class="md-raised md-primary"
              style="margin-left: 0px; margin-right: 0px; width: 100%"
              @click="addNewSubjectBundling"
            >
              <i class="tim-icons icon-simple-add"></i>&nbsp;Add New
            </md-button>
          </div>
          <div class="col-md-6">
            <md-button
              class="md-raised md-accent"
              style="margin-left: 0px; margin-right: 0px; width: 100%"
              @click="saveNewSubjectBundling"
            >
              <i class="tim-icons icon-upload"></i>&nbsp;Save
            </md-button>
          </div>
        </div>
      </card>
    </div>
  </div>
</template>

<script>
export default {
  name: "subject_bundling",
  components: {},
  data() {
    return {
      SubjectBundlingList: [],
      selectedIndex: null,
      newSubjectBundling: null,
    };
  },
  created() {
    this.loadSubjectBundling();
  },
  methods: {
    loadSubjectBundling() {
      this.apiGet("subjectBundlingList")
        .then((response) => {
          this.SubjectBundlingList = JSON.parse(
            response.data.array_string.replace(/\\/g, "")
          );
        })
        .catch((error) => {
          console.log(error);
        });
    },
    selectSubjectBundling(index) {
      this.selectedIndex = index;
    },
    addNewSubjectBundling() {
      // add binded value to pricing system array list
      this.SubjectBundlingList.push(this.newSubjectBundling);
      // after adding new item, clear data binding input
      this.newSubjectBundling = null;
    },
    deleteSubjectBundlingItem(index) {
      // no need for simple array popping process
      // splice array item by index given
      this.SubjectBundlingList.splice(index, 1);
    },
    saveNewSubjectBundling() {
      // action involves APi, show confirmation message
      this.$swal({
        title: "Are You Sure?",
        text: "Confirm to save this new subject bundling.",
        icon: "question",
        showCancelButton: true,
      }).then((result) => {
        // user confirmed user registration approval action
        if (result.value) {
          // post data to API
          this.apiPost("subjectBundlingList", {
            array_string: JSON.stringify(this.SubjectBundlingList),
          })
            .then((response) => {
              // API response OK, show success message
              // and reload pricing system list data
              this.loadSubjectBundling();
              this.showSuccessMessage(response.data.message);
            })
            .catch((error) => {
              // API response failed, log error message
              console.log(error);
            });
        }
      });
    },
    moveSubjectBundlingIndex(event) {
      // filter input for up/down arrow execute particular process
      if (event.key == "ArrowUp") {
        // because this event is decreasing index,
        // only process index above 0
        // else, do nothing
        if (this.selectedIndex > 0) {
          this.array_move(
            this.SubjectBundlingList,
            this.selectedIndex,
            this.selectedIndex - 1
          );

          // selected index will be decreased too
          this.selectedIndex--;
        }
      }
      if (event.key == "ArrowDown") {
        // because this event is increasing index,
        // only process index below max length of array
        // else, do nothing
        if (this.selectedIndex < this.SubjectBundlingList.length - 1) {
          this.array_move(
            this.SubjectBundlingList,
            this.selectedIndex,
            this.selectedIndex + 1
          );

          // selected index will be increased too
          this.selectedIndex++;
        }
      }
    },
    array_move(arr, old_index, new_index) {
      if (new_index >= arr.length) {
        var k = new_index - arr.length + 1;
        while (k--) {
          arr.push(undefined);
        }
      }
      arr.splice(new_index, 0, arr.splice(old_index, 1)[0]);
      return arr;
    },
  },
};
</script>

<style scoped>
.md-list {
  width: 320px;
  max-width: 100%;
  display: inline-block;
  vertical-align: top;
  border: 1px solid rgba(#000, 0.12);
}
</style>
<template>
  <div class="row">
    <div class="col-md-12">
      <div class="row">
        <div class="col-lg-6" style="margin: 0px auto">
          <card>
            <div class="row">
              <div class="col-md-12">
                <md-field style="margin-top: 0px;">
                  <md-icon class="md-clear">
                    <i
                      class="tim-icons icon-zoom-split"
                      style="font-size: 16pt"
                    ></i>
                  </md-icon>
                  <label>Keyword berdasarkan nama atau email user</label>
                  <md-input
                    v-model="search_keyword"
                    @keyup.enter.native="searchUserByKeyword"
                  ></md-input>
                  <span class="md-helper-text">
                    Tekan Enter untuk melakukan pencarian berdasarkan keyword.
                  </span>
                </md-field>
              </div>
            </div>
          </card>
        </div>
      </div>

      <div class="row">
        <div class="col-lg-6" style="margin: -10px auto">
          <card class="text-center">
            <md-chip class="col-12" v-if="search_result.length <= 0">
              No user found.
            </md-chip>
            <md-chip 
              class="col-12 md-primary mb-2" 
              md-clickable
              v-else 
              v-for="result in search_result" 
              :key="result.id"
              @click="showUpdatePasswordModal(result)"
              style="margin: 0px auto;"
            > 
              {{ result.name }}
            </md-chip>
          </card>
        </div>
      </div>

      <div class="row" v-if="Object.keys(selectedUser).length > 0">
        <div class="col-lg-6" style="margin: 0px auto;">
          <card>
            <div class="row">
              <div class="col-md-12">
                <md-field style="margin-top: 0px; margin-bottom: 0px;">
                  <label>New User Password</label>
                  <md-input v-model="newUserPassword" type="password"></md-input>
                </md-field>
              </div>
            </div>
          </card>
          <div class="row">
            <div class="col-md-12 text-right" style="margin-top: -10px;">
              <md-button class="md-raised md-accent" style="margin-right: 0px; margin-top: 0px;" @click="updateUserPassword">
                <i class="tim-icons icon-send"></i>&nbsp;Change User Password
              </md-button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Card from "../components/Cards/Card.vue";
export default {
  name: "reset-user-password",
  components: {
    Card,
  },
  data() {
    return {
      search_keyword: null,
      search_result: [],
      selectedUser: {}, 
      newUserPassword: null
    };
  },
  created() {
    window.aku = this;
  },
  methods: {
    searchUserByKeyword() {
      this.apiGet("searchUserByKeyword/" + this.search_keyword)
        .then((response) => {
          this.search_result = response.data;

          // empty seleted user and new password data
          this.selectedUser = {};
          this.newUserPassword = null;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    showUpdatePasswordModal(userId) {
      this.selectedUser = userId;
    },
    updateUserPassword() {
      // send data to user password update API
      this.apiPost('updateUserPassword', {
        user_id: this.selectedUser.id, 
        new_password: this.newUserPassword
      })
      .then(response => {
        if (response.status == 200) {
          // API response successful
          // show success message
          this.showSuccessMessage(response.data.message);

          // reset all form fields and values
          this.resetForm();
        }
      })
      .catch(error => {
        console.log(error);
      })
    },
    resetForm() {
      this.search_keyword = null;
      this.search_result = [];
      this.selectedUser = {};
      this.newUserPassword = null
    }
  },
};
</script>

<style lang="scss" scoped>
</style>
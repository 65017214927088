<template>
  <div class="row" style="color: #b596ad">
    <div class="col-md-12">
      <div
        style="
          background-color: #b00202;
          border-radius: 5px;
          padding: 15px 15px 15px 15px;
          border-left: 8px solid #7a0101;
          color: whitesmoke;
          -moz-user-select: none;
          -khtml-user-select: none;
          -webkit-user-select: none;
          -ms-user-select: none;
          user-select: none;
        "
      >
        <h3 style="border-bottom: 2px solid #7a0101; margin-bottom: 10px">
          Catatan
        </h3>

        <ul style="margin-left: -20px">
          <li>
            Modul ini digunakan untuk membuka ujian atau TRY OUT pada periode
            aktif yang sedang berjalan di sistem Sejawat.
          </li>
          <li>
            Pengisian modul ini cukup straightforward dengan melengkapi semua
            inputan yang disediakan.
          </li>
          <li>
            Ujian yang dibuka secara default akan terisi dengan 120 soal. Namun,
            dapat diganti dengan jumlah soal sesuai dengan kebutuhan dengan
            minimum 50 dan maks 150 soal.
          </li>
          <li>
            Soal akan terdistribusi secara otomatis sesuai dengan jumlah
            kategori (12 kategori yang tersedia jika tidak ada kategori yang
            terpilih).
          </li>
          <li>
            Jika ada satu atau lebih kategori yang terpilih maka soal ujian akan
            terbentuk sesuai dengan kategori yang dipilih saja.
          </li>
        </ul>
      </div>
    </div>
    <div class="col-md-12 mt-3">
      <div class="row" style="margin-top: 15px">
        <div class="col-md-6">
          <card style="margin-bottom: 18px">
            <md-field style="margin-top: 0px; margin-bottom: 0px">
              <label>Select Active Period</label>
              <md-select
                v-model="form.selectedPeriod"
                :disabled="form.freeExam"
              >
                <md-option
                  v-for="(period, index) in periodsList"
                  :key="index"
                  :value="period.id"
                >
                  {{ formatDate(period.start_date) }} s/d
                  {{ formatDate(period.end_date) }}
                </md-option>
              </md-select>
            </md-field>
          </card>
        </div>
        <div class="col-md-6">
          <card style="margin-bottom: 18px">
            <md-switch
              v-model="form.freeExam"
              style="margin-bottom: 7px"
              @change="
                () => {
                  form.selectedPeriod = null;
                }
              "
            >
              Or choose this option to open free independent exam.
            </md-switch>
          </card>
        </div>
      </div>

      <div class="row" style="margin-top: 15px">
        <div class="col-md-6">
          <card>
            <div class="row">
              <div class="col-md-12">
                <md-datepicker
                  v-model="form.startDate"
                  :md-immediately="true"
                  style="margin-bottom: 10px; margin-top: 0px"
                >
                  <label>Start Date Exam Period</label>
                </md-datepicker>
              </div>
              <div class="col-md-12">
                <md-datepicker
                  v-model="form.endDate"
                  :md-immediately="true"
                  style="margin-top: 5px"
                >
                  <label>End Date Exam Period</label>
                </md-datepicker>
              </div>
            </div>
          </card>
        </div>
        <div class="col-md-6">
          <card>
            <h3 style="margin-bottom: 15px">Exam Questions Properties</h3>
            <md-divider
              style="background-color: #a0dac0; margin-bottom: 15px"
            ></md-divider>
            <div class="row">
              <div class="col-md-6">
                <md-field>
                  <label>Questions Count (default: 120)</label>
                  <md-input
                    v-model="form.questionsCount"
                    type="number"
                    @blur="checkMinMaxCount($event)"
                    :key="questionsCountKey"
                  ></md-input>
                </md-field>
              </div>
              <div class="col-md-6">
                <md-field>
                  <label>Duration (in minutes)</label>
                  <md-input
                    v-model="form.duration"
                    type="number"
                    @blur="checkMinMaxDuration($event)"
                    :key="durationKey"
                  ></md-input>
                </md-field>
              </div>
            </div>
          </card>
        </div>
      </div>

      <!-- Added @ 19-02-2023 by Richard -->
      <div class="row">
        <div class="col-md-12">
          <card>
            <h3 style="margin-bottom: 15px">Exam Questions Categories</h3>
            <md-divider
              style="background-color: #a0dac0; margin-bottom: 15px"
            ></md-divider>
            <div class="row">
              <div
                class="col-md-3"
                v-for="questionCategory in questionCategories"
                v-bind:key="questionCategory"
              >
                <md-checkbox
                  v-model="form.selectedCategories"
                  :value="questionCategory"
                >
                  {{ questionCategory }}
                </md-checkbox>
              </div>
            </div>
          </card>
        </div>
      </div>

      <div class="row" style="margin-top: -15px">
        <div class="col-md-12 text-right">
          <md-button
            class="md-raised md-accent"
            style="margin-right: 0px"
            @click="submitPeriodExam"
          >
            <i class="tim-icons icon-send"></i>&nbsp;Save New Exam Period
          </md-button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Card from "../../components/Cards/Card.vue";
export default {
  components: { Card },
  name: "master_exam",
  data() {
    return {
      periodsList: [],
      form: {
        selectedPeriod: null,
        freeExam: false,
        startDate: null,
        endDate: null,
        questionsCount: 120,
        duration: 120,
        selectedCategories: [],
      },
      questionsCountKey: 0,
      durationKey: 0,
      questionCategories: [],
    };
  },
  created() {
    window.aku = this;

    // get active periods list
    this.apiGet("getActivePeriod")
      .then((response) => {
        this.periodsList = response.data;
      })
      .catch((error) => {
        console.log(error);
      });

    // get question list from API
    this.apiGet("getQuestionCategories")
      .then((response) => {
        this.questionCategories = JSON.parse(
          response.data.array_string.replace(/\\/g, "")
        );
      })
      .catch((error) => {
        console.log(error);
      });
  },
  methods: {
    checkMinMaxCount() {
      if (this.form.questionsCount < 50) {
        this.form.questionsCount = 50;
        this.form.questionsCountKey++;
      } else if (this.form.questionsCount > 150) {
        this.form.questionsCount = 150;
        this.form.questionsCountKey++;
      }
    },
    changeFreeOptions(event) {
      console.log(event);
    },
    checkMinMaxDuration() {
      if (this.form.duration < 60) {
        this.form.duration = 60;
        this.form.durationKey++;
      } else if (this.form.duration > 120) {
        this.form.duration = 120;
        this.form.durationKey++;
      }
    },
    submitPeriodExam() {
      // validate inputs
      let isValid = true;

      // check active period selection
      if (this.form.selectedPeriod === null && !this.form.freeExam) {
        isValid = false;
        this.showErrorWithCustomTitleAndContent(
          "Input Invalid",
          "Active period must be selected."
        );
      }

      // check start date cannot be empty
      if (this.form.startDate === null) {
        isValid = false;
        this.showErrorWithCustomName("Start date");
      }

      // check end date cannot be empty
      if (this.form.endDate === null) {
        isValid = false;
        this.showErrorWithCustomName("End date");
      }

      // check start and end date cannot be backdated
      console.log(this.form.endDate - this.form.startDate);

      if (this.form.endDate - this.form.startDate < 0) {
        isValid = false;
        this.showErrorWithCustomTitleAndContent(
          "Input Invalid",
          "Start date must be less or same than end date."
        );
      }

      // check validation flag
      if (isValid) {
        // beware of date input will be autu converted by axios
        // parse it into string using moment plugin
        const moment = require("moment");
        const startDateStr = moment(this.form.startDate).format(
          "YYYY-MM-DD HH:mm:ss"
        );
        const endDateStr = moment(this.form.endDate).format(
          "YYYY-MM-DD HH:mm:ss"
        );

        Object.assign(this.form, {
          startDate: startDateStr,
          endDate: endDateStr,
        });

        // all inputs is set and ready to go
        // but before all, user must confirm this action
        this.$swal({
          title: "Are You Sure?",
          text: "Confirm to submit new exam period.",
          icon: "question",
          showCancelButton: true,
        }).then((result) => {
          if (result.value) {
            // user confirmation aqcuired, post form data to API
            this.apiPost("createExamPeriod", this.form)
              .then((response) => {
                // api response OK, show success message
                this.showSuccessMessage(response.data.message);

                // reset this form input
                this.resetInput();
              })
              .catch((error) => {
                console.log(error);
              });
          }
        });
      }
    },
    resetInput() {
      this.form = {
        selectedPeriod: null,
        freeExam: false,
        startDate: null,
        endDate: null,
        questionsCount: 120,
        duration: 120,
        selectedCategories: [],
      };
    },
  },
};
</script>
<template>
  <div class="row">
    <div class="col-md-12">
      <md-table class="main-table" v-model="searched" :md-sort.sync="currentSort" :md-sort-order.sync="currentSortOrder"
        :md-sort-fn="customSort" md-card>
        <md-table-toolbar>
          <h3 class="md-title">Questions List</h3>
          <div class="col-md-4">
            <md-field md-clearable class="md-toolbar-section-end">
              <i class="tim-icons icon-zoom-split" style="margin-right: 10px"></i>
              <md-input placeholder="Search by question or category..." v-model="search" @keydown="searchQuestion" />
            </md-field>
          </div>
        </md-table-toolbar>

        <md-table-empty-state md-label="No Questions Available"
          :md-description="`Create new questions to make that appears on this list.`">
        </md-table-empty-state>

        <md-table-row slot="md-table-row" slot-scope="{ item }">
          <md-table-cell md-label="Question Text" md-sort-by="question_text" style="width: 38%; padding:">
            <div style="
              max-height: 20vh;
              overflow-y: auto;
              text-overflow: ellipsis;
              white-space: wrap;
              text-align: justify;">
              <b>{{ item.question_category }}: ({{ (item.question_code) ? item.question_code : 'no code' }}) </b><br>
              {{ item.question_text }}
            </div>
          </md-table-cell>
          <md-table-cell md-label="Answer Options" style="width: 20%;">
            <div style="
              max-height: 20vh;
              overflow-y: auto;
              text-overflow: ellipsis;
              white-space: wrap;">
              <ol type="A" style="margin-left: -23px;">
                <li v-if="item.correct_answer == 'A'" style="font-weight: bold; color: orange;">
                  {{ item.option1 }}
                </li>
                <li v-else>{{ item.option1 }}</li>
                <li v-if="item.correct_answer == 'B'" style="font-weight: bold; color: orange;">
                  {{ item.option2 }}
                </li>
                <li v-else>{{ item.option2 }}</li>
                <li v-if="item.correct_answer == 'C'" style="font-weight: bold; color: orange;">
                  {{ item.option3 }}
                </li>
                <li v-else>{{ item.option3 }}</li>
                <li v-if="item.correct_answer == 'D'" style="font-weight: bold; color: orange;">
                  {{ item.option4 }}
                </li>
                <li v-else>{{ item.option4 }}</li>
                <li v-if="item.correct_answer == 'E'" style="font-weight: bold; color: orange;">
                  {{ item.option5 }}
                </li>
                <li v-else>{{ item.option5 }}</li>
              </ol>
            </div>
          </md-table-cell>
          <md-table-cell md-label="explanation" style="width=20%">
            <div style="
              max-height: 20vh;
              overflow-y: auto;
              text-overflow: ellipsis;
              white-space: wrap;
              text-align: justify;">
              {{ (item.explanation) ? item.explanation : '-' }}
            </div>
          </md-table-cell>
          <md-table-cell md-label="Attachment" style="width: 10%;">
            <a target="_blank" v-bind:href="backend_root + '/files/' + item.image_name" v-if="item.image_name !== null">
              <img v-bind:src="backend_root + '/files/' + item.image_name" style="height: 75px" alt="" />
            </a>
            <span v-else>No image</span>
          </md-table-cell>
          <md-table-cell md-label="Action" style="width: 12%;">
            <base-button icon round class="btn-primary mr-1" title="Edit Question" @click="editQuestion(item.id)">
              <i class="tim-icons icon-pencil"></i>
            </base-button>
            <base-button icon round class="btn-danger" title="Delete Question" @click="deleteQuestion(item.id)">
              <i class="tim-icons icon-trash-simple"></i>
            </base-button>
          </md-table-cell>
        </md-table-row>
      </md-table>
    </div>
  </div>
</template>

<script>
const toLower = (text) => {
  return text.toString().toLowerCase()
}
const searchByName = (items, term) => {
  if (term) {
    return items.filter(
      (item) =>
        toLower(item.question_text).includes(toLower(term)) ||
        toLower(item.question_category).includes(toLower(term))
    )
  }

  return items
}

export default {
  name: "manage-questions",
  data() {
    return {
      questions_list: [],
      currentSort: "name",
      currentSortOrder: "asc",
      searched: [],
      search: null,
      tableKey: 0,
    }
  },
  created() {
    // call get value function
    this.getAllQuestions()
  },
  methods: {
    getAllQuestions() {
      // get valid period request to API
      this.apiGet("getAllQuestions", {
        paginate: 100
      })
        .then((response) => {
          this.questions_list = response.data.data
          this.searched = this.questions_list
        })
        .catch((error) => {
          console.log(error)
        })
    },
    searchOnTable() {
      this.searched = searchByName(this.questions_list, this.search)
    },
    searchQuestion(e) {
      if (e.code.toUpperCase() === 'ENTER') {
        console.log('masuk sini')
        const apiPayload = this.search ? { search: this.search } : { paginate: 100 }
        this.apiGet("getAllQuestions", apiPayload)
          .then(response => {
            if (this.search) {
              this.questions_list = response.data
            } else {
              this.questions_list = response.data.data
            }

            this.searched = this.questions_list
          })
      }
    },
    customSort(value) {
      return value.sort((a, b) => {
        const sortBy = this.currentSort

        if (this.currentSortOrder === "desc") {
          return a[sortBy].localeCompare(b[sortBy])
        }

        return b[sortBy].localeCompare(a[sortBy])
      })
    },
    editQuestion(id) {
      this.$router.push("/manage-questions/" + id)
    },
    deleteQuestion(id) {
      // deleting question is considered dangerous action
      // make user confirm their action
      this.$swal({
        title: "Are You Sure?",
        text: "This question will be deleted permanently.",
        icon: "question",
        showCancelButton: true,
      }).then((result) => {
        if (result.value) {
          // user confirmed the action,
          // proceed with API delete request
          this.apiDelete("deleteQuestion/" + id)
            .then((response) => {
              // show delete success message
              this.showSuccessMessage(response.data.message)
              this.getAllQuestions()
            })
            .catch((error) => {
              this.showApiErrorMessage(
                "Server error! cannot delete this question. Some exams already using this question."
              )
            })
        }
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.table-container {

  // custom scrollbar CSS
  ::-webkit-scrollbar {
    width: 5px;
  }

  ::-webkit-scrollbar-track {
    // background: #f1f1f1; 
    background-color: transparent;
  }

  ::-webkit-scrollbar-thumb {
    background-color: #565;
    border-radius: 50px;
    // border: 6px solid transparent;
    background-clip: content-box;
  }

  ::-webkit-scrollbar-thumb:hover {
    background: #545;
  }
}
</style>